import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const BlankPage = () => {
    const seo = {
        metaDesc: 'Supporting inventors world-wide - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Supporting inventors world-wide - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />

        </Layout>
    )
};

export default BlankPage;